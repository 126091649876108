/* Resets */
* {
  padding: 0;
  border: none;
  margin: 0;
}
html {
  font-family: sans-serif;
}
ul {
  display: inline-block;
  list-style: none;
}
li {
  display: inline-block;
  margin-left: -1px;
  /* for a weird 1 px gap that appears, even after 
     addressing the whitespace issue */
}
li:first-child {
  margin-left: 0;
}
a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}
button,
button:hover,
button:active,
button:focus {
  border: none;
  outline: none;
}
input,
input:focus {
  border: none;
  outline: none;
}

/* End resets */

/* Main */

@font-face {
  font-family: Decker;
  src: url(Decker.ttf);
}

html {
  overflow-x: hidden;
}

a, a:focus {
  color: #636363;
  text-decoration: underline;
}

a:hover, a:active {
  color: #636363;
  text-decoration: none;
}

body {
  background: #dddddd;
  color: #636363;
  font-family: Decker, Arial, sans-serif;
}

.button,
.button:focus {
  background: #dddddd;
  border: solid 2px #636363;
  border-radius: 10px;
  color: #636363;
  font-family: Decker, Arial, sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
}

.button:hover,
.button:active {
  background: #636363;
  border: solid 2px #636363;
  color: #dddddd;
  cursor: pointer;
}

.button--disabled,
.button--disabled:active,
.button--disabled:focus,
.button--disabled:hover {
  background: #dddddd;
  border: solid 2px #dddddd;
  color: #636363;
  cursor: default;
}

.checkout {
  max-width: 333px;
}

.checkout__mint-button,
.checkout__mint-button:active,
.checkout__mint-button:focus,
.checkout__mint-button:hover {
  font-size: 1.31em;
  margin-left: 12px;
  width: 150px;
}

.checkout__quantity-and-mint-button {
  display: flex;
  margin-bottom: 12px;
}

.checkout__quantity-input,
.checkout__quantity-input:focus {
  background: #ffffff;
  border: solid 1px #636363;
  color: #636363;
  font-size: 2em;
  margin: 3px 0;
  padding: 3px 6px;
  text-align: center;
}

.checkout__quantity-label {
  font-size: 1.31em;
  padding: 0 6px 0 0;
}

.checkout__inactive-sale {
  font-size: 2em;
  margin-bottom: 12px;
  width: 330px;
}

.checkout__tally {
  font-size: 1.31em;
  margin-bottom: 12px;
}

.checkout__price {
  font-size: 1.31em;
  margin-bottom: 12px;
}

.checkout__rarible {
  font-size: 1.31em;
  margin-bottom: 12px;
}

.checkout__donation {
  font-size: 1em;
}

.checkout-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 30%;
  margin-top: -382px;
}

.connect-wallet-button {
  padding: 12px 6px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 238px;
}

.floor {
}

.footer {
  background-color: #636363;
  height: 300px;
  margin-left: auto;
  margin-top: 180px;
  width: 24%;
}

.footer__external-links {
  height: 48px;
  margin-left: 15%;
  padding-top: 9px;
  width: 156px;
}

.footer__external-link:hover,
.footer__external-link:focus,
.footer__external-link:active {
  opacity: 0.62;
}

.footer__external-link-1 {
  margin-right: 6px;
}

.footer__external-link-3 {
  margin-left: 6px;
}

.footer__copyright {
  color: #dddddd;
  font-size: 1em;
  margin-left: 30%;
  margin-top: 115px;
  width: 62%;
}

.gallery {
  margin-left: 15%;
  margin-top: -62px;
  width: 500px;
}

.gallery__label {
  font-weight: 700;
  margin: 6px 0;
}

.gallery__note {
  margin-top: 3px;
}

.gravitations-shop {
}

.notice {
  background: #636363;
  border-radius: 10px;
  bottom: 6%;
  color: #dddddd;
  display: none;
  filter: alpha(opacity=0);
  font-size: 1.31em;
  left: 50%;
  margin: 0 auto;
  opacity: 0;
  padding: 12px 24px;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
}

.notice a {
  color: #dddddd;
}

.poster {
  font-size: 1.31em;
  margin-left: 62%;
  margin-right: 9%;
  margin-top: 61px;
}

.sign {
  font-size: 2.62em;
  margin-left: 24%;
  margin-top: 100px;
}

.sticky-bar {
  background-color: #dddddd;
  border-bottom: solid 2px #636363;
  display: flex;
  position: fixed;
  height: 100px;
  top: 0;
  width: 100%;
}

.sticky-bar__title {
  font-size: 2em;
  height: fit-content;
  margin: auto 0 auto 24%;
}

.sticky-bar__connect-wallet-button {
  height: fit-content;
  margin: auto 24px auto auto;
  padding: 12px 6px;
  width: 238px;
}


@media only screen and (min-device-width: 1441px) and (max-device-width: 1680px) { 
  .checkout-container {
    margin-right: 24%;
  }
}

@media only screen and (min-device-width: 1281px) and (max-device-width: 1440px) { 
  .checkout-container {
    margin-right: 20%;
  }

  .gallery {
    margin-left: 9%;
  }
}

@media only screen and (min-device-width: 1113px) and (max-device-width: 1280px) {
  .checkout-container {
    margin-right: 20%;
  }

  .gallery {
    margin-left: 9%;
    width: 400px;
  }

  .gallery__image {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1112px) {
  .checkout-container {
    margin-right: 13%;
    margin-top: -300px;
  }

  .footer {
    width: 38%;
  }

  .gallery {
    margin-left: 9%;
    margin-top: -162px;
    width: 400px;
  }

  .gallery__image {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (min-device-width: 993px) and (max-device-width: 1024px) {
  .checkout-container {
    margin-right: 11%;
    margin-top: -300px;
  }

  .footer {
    width: 38%;
  }

  .gallery {
    margin-left: 9%;
    margin-top: -162px;
    width: 400px;
  }

  .gallery__image {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (min-device-width: 835px) and (max-device-width: 992px) {
  .checkout-container {
    margin-right: 10%;
    margin-top: -300px;
  }

  .footer {
    margin-top: 80px;
    width: 38%;
  }

  .gallery {
    margin-left: 9%;
    margin-top: -131px;
    width: 300px;
  }

  .gallery__image {
    width: 300px;
    height: 300px;
  }

  .poster {
    margin-left: 53%;
  }
}

@media only screen and (min-device-width: 1px) and (max-device-width: 834px) {
  html, body {
    overflow-x: hidden;
  }

  .checkout-container {
    display: block;
    margin: 48px auto 0;
    width: 400px;
  }

  .checkout {
    max-width: 400px;
    margin: 0 auto;
    width: 400px;
  }

  .connect-wallet-button {
    padding: 12px 6px;
    position: absolute;
    right: 5%;
    top: 24px;
    width: 28%;
  }

  .footer {
    height: 300px;
    margin: 96px 0 0;
    width: 100%;
  }

  .footer__external-links {
    height: 48px;
    margin: 0 auto;
    padding-top: 24px;
    width: fit-content;
  }

  .footer__external-link-1 {
    margin-right: 12px;
  }

  .footer__external-link-3 {
    margin-left: 12px;
  }

  .footer__copyright {
    margin: 115px auto 0;
    text-align: center;
    width: 80%;
  }

  .gallery {
    margin: 24px auto;
    width: 400px;
  }

  .gallery__image {
    width: 400px;
    height: 400px;
  }

  .poster {
    margin: 48px auto;
    width: 400px;
  }

  .sign {
    font-size: 2em;
    height: 100px;
    line-height: 100px;
    padding-left: 9%;
    margin: 0;
    width: 100%;
  }

  .sticky-bar__title {
    height: fit-content;
    margin: auto 0 auto 9%;
  }

  .sticky-bar__connect-wallet-button {
    height: fit-content;
    margin: auto 5% auto auto;
    padding: 12px 6px;
    width: 28%;
  }

  @media only screen and (min-device-width: 381px) and (max-device-width: 450px) {
    .checkout-container {
      width: 367px;
    }

    .checkout {
      max-width: 367px;
      width: 367px;
    }

    .gallery {
      margin: 24px auto;
      width: 300px;
    }

    .gallery__image {
      width: 300px;
      height: 300px;
    }

    .poster {
      margin: 48px auto;
      width: 300px;
    }
  }

  @media only screen and (min-device-width: 1px) and (max-device-width: 380px) {
    .checkout-container {
      width: 333px;
    }

    .checkout {
      max-width: 333px;
      width: 333px;
    }

    .gallery {
      margin: 24px auto;
      width: 300px;
    }

    .gallery__image {
      width: 300px;
      height: 300px;
    }

    .poster {
      margin: 48px auto;
      width: 300px;
    }
  }
}

/* End main */
